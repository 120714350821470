<template>
  <action-add-new action-url="location/zones" button-label="Adauga zona noua">

    <template v-slot:title>
      Adauga zona noua
    </template>

    <template v-slot:fields="{form, errors}">
      <v-text-field v-model="form.name" dense hide-details outlined placeholder="Nume" single-line/>
      <error-messages :errors="errors.name"/>
    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages,
    ActionAddNew
  }
}
</script>
