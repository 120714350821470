<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      resource-path="location/zones"
    >

      <template v-slot:actions>
        <create-form/>
      </template>

      <template v-slot:item.actions="{item}">
        <action-delete :action-url="`location/zones/${item.id}`"/>
        <edit-form :item="item"/>
      </template>

    </cit-data-table>

  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import CreateForm from './Create'
import EditForm from './Edit'

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '80',
          sortable: true
        },
        {
          text: 'Nume',
          value: 'name',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          align: 'center',
          width: '140',
          sortable: true
        }
      ]
    }
  }
}
</script>
